import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import Navbar from '../Navbar/Navbar';
import { SideBar } from '../SideBar/SideBar';
import { VideoPlayer } from '../VideoPlayer/VideoPlayer';
import { Footer } from '../Footer/Footer';
import { useParams } from 'react-router-dom';
import { useHttp } from '../../hooks/http.hook';
import { Loader } from '../Loader/Loader';
import { useCrypto } from '../../hooks/crypto.hook';
import { useTitle } from '../../hooks/title.hook';
import { Error } from '../Error/Error';
import { LanguageContext } from '../../context/LanguageContext';

export const MainPage = ({ isMobileMenuOpen, setIsMobileMenuOpen, windowWidth }) => {
  const { token } = useParams();
  const videoRef = useRef(null);
  const { decryptData } = useCrypto();
  const { language, setTsna, showToastMessage } = useContext(LanguageContext);

  const { request, loading } = useHttp(token);
  const [error, setError] = useState(null);
  const [videos, setVideos] = useState([]);
  const [video, setVideo] = useState({});
  const [currentTimeLine, setCurrentTimeLine] = useState(0);
  const [firstName, setFirstName] = useState('');

  const pageTitle = video?.videoName ? `${video?.videoName}` : 'PhishFirewall Training';
  useTitle(pageTitle);

  const toggleVideoPlay = () => {
    videoRef?.current?.pause();
  };

  const fetchVideos = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(`/api/user/${language}/videos`, 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptRes = decryptData(fetched);
        if (decryptRes?.error) setError(decryptRes);

        if (decryptRes?.videos.find(v => v.translateServiceNotAvailable)) {
          setTsna(true);
          showToastMessage('Sorry, a full translation to this language will appear later')
        }
        else {
          setVideo(decryptRes?.videos?.[0]);
          setVideos(decryptRes?.videos);
          setFirstName(decryptRes?.firstName);
          setCurrentTimeLine(0);
        }

        return decryptRes;
      }
    } catch (err) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, language]);

  useEffect(() => {
    if (language) fetchVideos();
  }, [fetchVideos, language]);

  return (
    <div className='page'>
      {!error
        ? <>
            <Navbar
              isMobileMenuOpen={isMobileMenuOpen}
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              welcomePage={false}
              videos={videos}
              loading={loading}
              toggleVideoPlay={toggleVideoPlay}
              language={language}
              firstName={firstName}
            />

            {!loading
              ? <main className='main'>
                  <SideBar
                    windowWidth={windowWidth}
                    isMobileMenuOpen={isMobileMenuOpen}
                    setIsMobileMenuOpen={setIsMobileMenuOpen}
                    videos={videos}
                    video={video}
                    setVideo={setVideo}
                    setCurrentTimeLine={setCurrentTimeLine}
                  />
                  <VideoPlayer
                    videos={videos}
                    currentVideo={video}
                    isMobileMenuOpen={isMobileMenuOpen}
                    setVideo={setVideo}
                    setVideos={setVideos}
                    videoRef={videoRef}
                    currentTimeLine={currentTimeLine}
                    setCurrentTimeLine={setCurrentTimeLine}
                  />
                </main>
              : <Loader/>
            }
          </>
        : <>
            <Navbar
              welcomePage={true}
            />
            {!loading
              ? <Error error={error} />
              : <Loader />
            }
          </>
      }

      <Footer />
    </div>
  )
};
