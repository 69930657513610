import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as Rewatch } from '../../images/icons/rewatch.svg';
import cn from 'classnames';
import chevronLeft from '../../images/icon _chevron-left_.svg';
import chevronRight from '../../images/icon _chevron-right_.svg';
import Player from '../Player/Player';
import { useHttp } from '../../hooks/http.hook';
import { useCrypto } from '../../hooks/crypto.hook';
import { CustomModal } from '../Modal/Modal';
import './VideoPlayer.css';

export const FasttrackComplianceVideoPlayer = ({
  currentVideo, isMobileMenuOpen, videos, setVideo, setVideos, videoRef,
  setCurrentTimeLine, currentTimeLine, categoryName
}) => {
  const videoIndex = videos.findIndex(v => v?.videoName === currentVideo?.videoName);
  const { token } = useParams();
  const { requestWithSecondaryLoading } = useHttp(token);
  const { encryptData } = useCrypto();
  const modalTimerRef = useRef(null);
  const { t } = useTranslation();

  const [currentProgress, setCurrentProgress] = useState(0);
  const [currentResolution, setCurrentResolution] = useState(1280);
  const [resolutions, setResolutions] = useState([]);
  const [thumbnail, setThumbnail] = useState('');
  const [card, setCard] = useState('video');
  const [autoPlay, setAutoPlay] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAlreadyShown, setModalAlreadyShown] = useState(false);

  useEffect(() => {
    if (currentVideo?.thumbnail) {
      setThumbnail(currentVideo?.thumbnail)
    }
  }, [currentVideo?.thumbnail, card]);

  const updateViewed = useCallback(async (currentVideo, correctAnswer) => {
    try {
      if (token) {
        const ecryptedData = encryptData({
          templateId: currentVideo.templateId,
          segmentId: currentVideo.segmentId,
          correctAnswer
        });
        const fetched = await requestWithSecondaryLoading(`/api/fasttrack/update`, 'PATCH', { data: ecryptedData }, {
          Authorization: `Bearer ${token}`
        })

        return fetched;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    setCurrentProgress(0);
    const video = videoRef.current;

    if (video) {
      const assetsResolutions = [...new Set(currentVideo?.assets?.map(a => a.width))].sort((a, b) => b - a);
      setResolutions(assetsResolutions);

      let videoUrl = currentVideo?.assets?.find(a => a.width === currentResolution)?.url;

      if (!assetsResolutions.includes(currentResolution)) {
        setCurrentResolution(assetsResolutions.at(0));
        videoUrl = currentVideo?.assets?.find(a => a.width === assetsResolutions.at(-1))?.url;
      }

      video.src = videoUrl;
      video.load();
    }
  }, [videoRef, currentVideo, currentResolution, card]);

  const setPreviousVideo = () => {
    setCurrentTimeLine(0);
    setVideo(videos.at(videoIndex - 1));
  }

  const setNextVideo = () => {
    setCurrentTimeLine(0);
    setVideo(videos.at(videoIndex + 1));
  };

  const handleAnswer = (answer) => {
    setVideos(prev => prev.map((item, index) => {
      if (index === videoIndex) {
        return {
          ...item,
          isLandingViewed: true,
        };
      } else {
        return item;
      }
    }));
    const updateViewedAndProceed = async (currentVideo, correctAnswer) => {
      await updateViewed(currentVideo, correctAnswer);
    };

    const answerIsCorrect = (currentVideo?.lpQuestion?.answers?.indexOf(answer) + 1) === +currentVideo?.lpQuestion?.correctAnswer;

    if (answerIsCorrect) {
      setCard('correctAnswer');
      updateViewedAndProceed(currentVideo, true);
    } else {
      setCard('wrongAnswer');
      updateViewedAndProceed(currentVideo, false);
    }

    const isUnviewedVideo = videos
      .filter((_, index) => index !== videoIndex)
      .some(v => !v.isLandingViewed);

    if (videoIndex === videos.length - 1 && !isUnviewedVideo && !modalAlreadyShown) {
      modalTimerRef.current = setTimeout(() => {
        setModalOpen(true);
      }, 3000);
    }
  }

  const handleRewatch = () => {
    if (modalTimerRef.current) {
      clearTimeout(modalTimerRef.current);
    }
    setCard('video');
  }

  useEffect(() => {
    if (currentProgress === 100) {
      setCurrentProgress(0);
      if (currentVideo?.lpQuestion?.question && currentVideo?.lpQuestion?.answers?.length) setCard('questions')
      else {
        setVideos(prev => prev.map((item, index) => {
          if (index === videoIndex) {
            return {
              ...item,
              isLandingViewed: true,
            };
          } else {
            return item;
          }
        }))

        if (videoIndex < videos.length - 1) {
          setCurrentTimeLine(0);
          setVideo(videos.at(videoIndex + 1));
        } else if (videoIndex === videos.length - 1) {
          setCurrentTimeLine(0);
          setVideo(videos.at(0));
          setAutoPlay(false);
        }

        const updateViewedAndProceed = async () => {
          await updateViewed(currentVideo);
        };

        updateViewedAndProceed();
      }
    };
  }, [currentProgress, currentVideo, setCurrentTimeLine, setVideo, setVideos, updateViewed, videoIndex, videos]);

  useEffect(() => {
    setCard('video');
  }, [currentVideo]);

  useEffect(() => {
    if (!modalAlreadyShown && videoIndex < videos.length - 1) {
      const isUnviewedVideo = videos.some(v => !v.isLandingViewed);

      if (!isUnviewedVideo) {
        setModalAlreadyShown(true);
        setModalOpen(true);
        setAutoPlay(false);
      }
    }
  }, [modalAlreadyShown, videoIndex, videos]);

  return (
    <>
      <article className={cn({ 'video-hide': isMobileMenuOpen })}>
        {!!videos?.length
            ? <div className="video-player-container">
                {(() => {
                  switch (card) {
                    case 'video':
                      return (
                        <>
                          <h3>{currentVideo?.videoName}</h3>
                          <Player
                            src={currentVideo?.videoUrl}
                            autoPlay={autoPlay}
                            videoRef={videoRef}
                            currentProgress={currentProgress}
                            setCurrentProgress={setCurrentProgress}
                            resolutions={resolutions}
                            currentResolution={currentResolution}
                            setCurrentResolution={setCurrentResolution}
                            currentTimeLine={currentTimeLine}
                            setCurrentTimeLine={setCurrentTimeLine}
                            thumbnail={thumbnail}
                            setThumbnail={setThumbnail}
                            video={currentVideo}
                            card={card}
                          />
                        </>
                      );
                    case 'questions':
                      return (
                        <div className='edu-question-container'>
                          <h4>{currentVideo?.lpQuestion?.question}</h4>
                          <span>{t('click_on')}</span>
                          <ul>
                            {currentVideo?.lpQuestion?.answers?.map((answer, index) => (
                              <li
                                className='edu-question'
                                role='button'
                                onClick={() => handleAnswer(answer)}
                                key={index}
                                style={{ animationDelay: `${(index + 1) * 0.2 }s` }}
                              >
                                {answer}
                              </li>
                            ))}
                          </ul>
                        </div>
                      );
                    case 'correctAnswer':
                      return (
                        <div className='edu-question-container'>
                          <h2 className='correct-answer'>{currentVideo?.lpQuestion?.correctAnswerTitle || t('great_job')}</h2>
                          {currentVideo?.lpQuestion?.correctAnswerDescription && <p>{currentVideo?.lpQuestion?.correctAnswerDescription}</p>}
                        </div>
                      );
                    case 'wrongAnswer':
                      return (
                        <div className='edu-question-container edu-fasttrack-question-container'>
                          <div>
                            <h2 className='wrong-answer'>{currentVideo?.lpQuestion?.wrongAnswerTitle || t('better_luck_next_time')}</h2>
                            {currentVideo?.lpQuestion?.wrongAnswerDescription && <p>{currentVideo?.lpQuestion?.wrongAnswerDescription}</p>}
                          </div>

                          <div>
                            <h5>{t('watch_video_again')}</h5>
                            <button
                              onClick={handleRewatch}
                            >
                              <Rewatch />
                            </button>
                          </div>

                          <div></div>
                        </div>
                      );
                    default:
                      return null;
                  }
                })()}

                <div className="video-player-navigate">
                  <button
                    onClick={setPreviousVideo}
                    disabled={videoIndex <= 0 || !videos?.[videoIndex - 1]?.isLandingViewed}
                  >
                    <div className='chevron-left'>
                      {t('Prev')}: <strong>{videos?.at(videoIndex - 1)?.videoName}</strong>
                    </div>
                    <img
                      src={chevronLeft}
                      alt='chevronLeft'
                    />
                  </button>
                  <button
                    onClick={setNextVideo}
                    disabled={
                      videoIndex >= videos.length - 1
                      || (!videos?.[videoIndex]?.isLandingViewed && !videos?.[videoIndex + 1]?.isLandingViewed)
                    }
                  >
                    <div className='chevron-right'>
                      {t('Next')}: <strong>{videos?.at(videoIndex + 1)?.videoName}</strong>
                    </div>
                    <img src={chevronRight} alt='chevronRight'></img>
                  </button>
                </div>
              </div>
            : <div className='welcome-page'>
                <h2>{t('congrats_description')}</h2>
              </div>
          }
      </article>

      <CustomModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <div className='modal-children-congrats'>
          <h2>{t('Congratulations!')}</h2>
          <p>{t('successfully_completed_1')} <strong>{categoryName}</strong>{t('successfully_completed_2')}</p>
        </div>
      </CustomModal>
    </>
  );
};
