import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../context/LanguageContext';
import { ReactComponent as Rewatch } from '../../images/icons/rewatch.svg';
import { Footer } from '../Footer/Footer';
import { useParams } from 'react-router-dom';
import { useHttp } from '../../hooks/http.hook';
import { Loader } from '../Loader/Loader';
import { useCrypto } from '../../hooks/crypto.hook';
import { useTitle } from '../../hooks/title.hook';
import { Error } from '../Error/Error';
import Navbar from '../Navbar/Navbar';
import Player from '../Player/Player';
import './TrainingPage.css';

export const TrainingPage = () => {
  const { token, videoId } = useParams();

  const videoRef = useRef(null);
  const { language, setTsna, showToastMessage } = useContext(LanguageContext);
  const { t } = useTranslation();
  const { decryptData, encryptData } = useCrypto();

  const { request, loading, requestWithSecondaryLoading } = useHttp(token);
  const [error, setError] = useState(null);
  const [videoItem, setVideoItem] = useState([]);
  const [video, setVideo] = useState({});
  const [currentTimeLine, setCurrentTimeLine] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [currentResolution, setCurrentResolution] = useState(1280);
  const [resolutions, setResolutions] = useState([]);
  const [card, setCard] = useState('video');
  const [thumbnail, setThumbnail] = useState('');
  const [secondThumbnail, setSecondThumbnail] = useState('');

  const pageTitle = video?.videoName ? ` | ${video?.videoName}` : '';
  useTitle('PhishFirewall Training' + pageTitle);

  const fetchVideo = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(`/api/template/tlp/${language}/${videoId}`, 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptRes = decryptData(fetched);

        if (decryptRes?.error) {
          setError(decryptRes);

          return;
        }
        else {
          setVideoItem(decryptRes);
          setCurrentTimeLine(0);
          setVideo(decryptRes.at(1));
          setThumbnail(decryptRes.at(1)?.thumbnail);
          setSecondThumbnail(decryptRes.at(1)?.thumbnail);
        }

        if (decryptRes?.at(1)?.translateServiceNotAvailable) {
          setTsna(true);
          showToastMessage('Sorry, a full translation to this language will appear later');
        }

        return decryptRes;
      }
    } catch (err) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, language]);

  useEffect(() => {
    if (language) fetchVideo();
  }, [fetchVideo, language]);

  useEffect(() => {
    setCurrentProgress(0)
    const currVideo = videoRef.current;

    if (currVideo) {
      const assetsResolutions = [...new Set(video?.assets?.map(a => a.width))].sort((a, b) => b - a);
      setResolutions(assetsResolutions);

      let videoUrl = video?.assets?.find(a => a.width === currentResolution)?.url;

      if (assetsResolutions?.length && !assetsResolutions.includes(currentResolution)) {
        setCurrentResolution(assetsResolutions.at(0));
        videoUrl = video?.assets?.find(a => a.width === assetsResolutions.at(-1))?.url;
      }

      setThumbnail(secondThumbnail);

      currVideo.src = videoUrl;
      currVideo.load();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef, video, currentResolution, card]);

  const createViewedAction = useCallback(async (video) => {
    try {
      if (token) {
        const ecryptedData = encryptData({ templateId: video.templateId, segmentId: video.segmentId });
        const fetched = await requestWithSecondaryLoading(`/api/template/viewed`, 'PATCH', { data: ecryptedData }, {
          Authorization: `Bearer ${token}`
        })

        return fetched;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const saveAnswer = useCallback(async (video, correctAnswer) => {
    try {
      if (token) {
        const ecryptedData = encryptData({
          templateId: video.templateId,
          segmentId: video.segmentId,
          correctAnswer
        });
        const fetched = await requestWithSecondaryLoading(`/api/template/answer`, 'PATCH', { data: ecryptedData }, {
          Authorization: `Bearer ${token}`
        })

        return fetched;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleAnswer = (answer) => {
    const saveAnswerAndProceed = async (video, correctAnswer) => {
      await saveAnswer(video, correctAnswer);
    };
    const answerIsCorrect = (videoItem?.at(1)?.lpQuestion?.answers?.indexOf(answer) + 1) === +videoItem?.at(1)?.lpQuestion?.correctAnswer;

    if (answerIsCorrect) {
      saveAnswerAndProceed(video, true);
      setCard('correctAnswer')
    } else {
      saveAnswerAndProceed(video, false);
      setCard('wrongAnswer')
    }
  }

  useEffect(() => {
    if (currentProgress === 100) {
      setCurrentProgress(0)

      const createViewedAndProceed = async () => {
        await createViewedAction(video);
      };

      if (videoItem?.at(1)?.lpQuestion?.question && videoItem?.at(1)?.lpQuestion?.answers?.length) setCard('questions')
      else                                                                                         setCard('nonquestions');

      createViewedAndProceed();
    }
  }, [createViewedAction, currentProgress, video, videoItem]);

  return (
    <div className='page'>
      {!error
        ? <>
            <Navbar
              loading={loading}
              templateVideo={true}
              videos={videoItem}
              language={language}
              firstName={videoItem?.at(0)}
            />

            {!loading
              ? <main className='main'>
                  <div className="video-player-container">
                    {(() => {
                      switch (card) {
                        case 'video':
                          return (
                            <>
                              <h3 className="video-name-edu">{video?.videoName}</h3>
                              <div className='video-player-edu'>
                                <Player
                                  src={video?.videoUrl}
                                  autoPlay={true}
                                  videoRef={videoRef}
                                  currentProgress={currentProgress}
                                  setCurrentProgress={setCurrentProgress}
                                  resolutions={resolutions}
                                  currentResolution={currentResolution}
                                  setCurrentResolution={setCurrentResolution}
                                  currentTimeLine={currentTimeLine}
                                  setCurrentTimeLine={setCurrentTimeLine}
                                  thumbnail={thumbnail}
                                  setThumbnail={setThumbnail}
                                  video={video}
                                />
                              </div>
                            </>
                          );
                        case 'questions':
                          return (
                            <div className='edu-question-container'>
                              <h4>{videoItem?.at(1)?.lpQuestion?.question}</h4>
                              <span>{t('click_on')}</span>
                              <ul>
                                {videoItem?.at(1)?.lpQuestion?.answers?.map((answer, index) => (
                                  <li
                                    className='edu-question'
                                    role='button'
                                    onClick={() => handleAnswer(answer)}
                                    key={index}
                                    style={{ animationDelay: `${(index + 1) * 0.2 }s` }}
                                  >
                                    {answer}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          );
                        case 'correctAnswer':
                          return (
                            <div className='edu-question-container'>
                              <h2 className='correct-answer'>{videoItem?.at(1)?.lpQuestion?.correctAnswerTitle || t('great_job')}</h2>
                              {videoItem?.at(1)?.lpQuestion?.correctAnswerDescription && <p>{videoItem?.at(1)?.lpQuestion?.correctAnswerDescription}</p>}
                            </div>
                          );
                        case 'wrongAnswer':
                          return (
                            <div className='edu-question-container edu-fasttrack-question-container'>
                              <div>
                                <h2 className='wrong-answer'>{videoItem?.at(1)?.lpQuestion?.wrongAnswerTitle || t('better_luck_next_time')}</h2>
                                {videoItem?.at(1)?.lpQuestion?.wrongAnswerDescription && <p>{videoItem?.at(1)?.lpQuestion?.wrongAnswerDescription}</p>}
                              </div>

                              <div>
                                <h5>{t('watch_video_again')}</h5>
                                <button
                                  onClick={() => setCard('video')}
                                >
                                  <Rewatch />
                                </button>
                              </div>

                              <div></div>
                            </div>
                          );
                        case 'nonquestions':
                        return (
                          <div className='edu-question-container'>
                            <h2 className='correct-answer'>
                              {videoItem?.at(1)?.lpQuestion?.correctAnswerTitle || t('thanks_for_watching')}
                            </h2>
                            <p>
                              {videoItem?.at(1)?.lpQuestion?.correctAnswerDescription || t('not_contain_any_additional_questions')
                              }
                            </p>
                          </div>
                        );
                        default:
                          return null;
                      }
                    })()}
                  </div>
                </main>
              : <Loader/>
            }
          </>
        : <>
            <Navbar
              welcomePage={true}
              language={language}
            />
            {!loading
              ? <Error error={error} />
              : <Loader />
            }
          </>
      }

      <Footer language={language} />
    </div>
  )
}
